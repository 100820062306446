import Pagination from 'rc-pagination/lib/locale/zh_TW';
import DatePicker from '../date-picker/locale/zh_TW';
import TimePicker from '../time-picker/locale/zh_TW';
import Calendar from '../calendar/locale/zh_TW';
export default {
    locale: 'zh-tw',
    Pagination,
    DatePicker,
    TimePicker,
    Calendar,
    Table: {
        filterTitle: '篩選器',
        filterConfirm: '確 定',
        filterReset: '重 置',
        selectAll: '全部選取',
        selectInvert: '反向選取',
    },
    Modal: {
        okText: '確 定',
        cancelText: '取 消',
        justOkText: 'OK',
    },
    Popconfirm: {
        okText: '確 定',
        cancelText: '取 消',
    },
    Transfer: {
        searchPlaceholder: '搜尋資料',
        itemUnit: '項目',
        itemsUnit: '項目',
    },
    Upload: {
        uploading: '正在上傳...',
        removeFile: '刪除檔案',
        uploadError: '上傳失敗',
        previewFile: '檔案預覽',
        downloadFile: '下载文件',
    },
    Empty: {
        description: '無此資料',
    },
    PageHeader: {
        back: '返回',
    },
};
