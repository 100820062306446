import Pagination from 'rc-pagination/lib/locale/ru_RU';
import DatePicker from '../date-picker/locale/ru_RU';
import TimePicker from '../time-picker/locale/ru_RU';
import Calendar from '../calendar/locale/ru_RU';
export default {
    locale: 'ru',
    Pagination,
    DatePicker,
    TimePicker,
    Calendar,
    Table: {
        filterTitle: 'Фильтр',
        filterConfirm: 'OK',
        filterReset: 'Сбросить',
        selectAll: 'Выбрать всё',
        selectInvert: 'Инвертировать выбор',
        sortTitle: 'Сортировка',
    },
    Modal: {
        okText: 'OK',
        cancelText: 'Отмена',
        justOkText: 'OK',
    },
    Popconfirm: {
        okText: 'OK',
        cancelText: 'Отмена',
    },
    Transfer: {
        searchPlaceholder: 'Поиск',
        itemUnit: 'элем.',
        itemsUnit: 'элем.',
    },
    Upload: {
        uploading: 'Загрузка...',
        removeFile: 'Удалить файл',
        uploadError: 'При загрузке произошла ошибка',
        previewFile: 'Предпросмотр файла',
        downloadFile: 'Загрузить файл',
    },
    Empty: {
        description: 'Нет данных',
    },
    Text: {
        edit: 'редактировать',
        copy: 'копировать',
        copied: 'скопировано',
        expand: 'раскрыть',
    },
    PageHeader: {
        back: 'назад',
    },
};
